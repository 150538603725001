import React from 'react';
import "./index.css";
import HeroImg from '../../../../assets/img/home-hero-img.png';
import CountUp from 'react-countup';

const HomeHero = () => {
    return (
        <div className="home-landing-wrapper">
            <section className="home-hero pl-6 pr-6">
                <div className="columns home-hero-columns">
                    <div className="column is-7 is-align-self-center">
                        <h1 className="title is-2 home-hero-title has-bold-text" data-aos="fade-up" data-aos-duration="900">
                            Experience Unmatched  <br />Precision &
                            <span className="home-hero-underlined ml-4">Speed</span>.
                        </h1>
                        <h1 className="title is-6 home-hero-subtitle mt-6 mb-6" data-aos="fade-up" data-aos-duration="900">
                            Get High-quality Survey Drawings in Record Time with Team 2's Professional Drafting Services
                        </h1>
                        <div className="home-hero-buttons-group" data-aos="fade-up" data-aos-duration="900">
                            <a className="button is-warning is-primary-button has-bold-text is-uppercase hvr-icon-forward mb-2" href="#contact-us">
                                Get in Touch
                                <i className="fas fa-arrow-right ml-2 hvr-icon"></i>
                            </a>
                            <a className="button is-outlined has-bold-text is-uppercase hvr-icon-forward mb-2 home-hero-work-button" href="#home_portfolio_section">
                                See our work
                                <i className="fas fa-arrow-right ml-2 hvr-icon"></i>
                            </a>
                        </div>
                        <div className="home-hero-stats mt-6 mb-4" data-aos="fade-up" data-aos-duration="900">
                            <div className="columns is-mobile">
                                <div className="column is-one-third-tablet is-half-mobile">
                                    <h1 className="title is-1 mb-0"><CountUp end={15} />+</h1>
                                    <h1 className="title is-4">Years of Experience</h1>
                                </div>
                                <div className="column is-one-third-tablet is-half-mobile">
                                    <h1 className="title is-1 mb-0 project-counter-desktop"><CountUp end={150000} /><span>+</span></h1>
                                    <h1 className="title is-1 mb-0 project-counter-mobile"><CountUp end={150} />K <span className="project-counter-plus">+</span></h1>
                                    <h1 className="title is-4">Projects Completed</h1>
                                </div>
                                <div className="column is-one-third-tablet"></div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-5 is-align-self-center">
                        <img className="home-hero-img" src={HeroImg} alt="Team 2 Engineers" data-aos="fade-in" data-aos-duration="900" />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HomeHero;