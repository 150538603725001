import React from "react";
import './index.css';
import HomeHero from "./components/HomeHero";
import AboutUs from "./components/AboutUs";
import ServicesBanner from "./components/Services";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import SiteFooter from "./components/Footer";

const HomePage = () => {
  return (
    <>
      <HomeHero />
      <AboutUs />
      {/* <ServicesBanner /> */}
      <Portfolio />
      <Contact />
    </>
  );
}

export default HomePage;
