import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './screens/HomePage';
import ShowcasePage from "./screens/ShowcasePage";
import AOS from 'aos';

const App = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/portfolio/:type" element={<ShowcasePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
