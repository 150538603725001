import React from 'react';
import './index.css';

const SiteFooter = (props) => {
    return (
        <section className="site-footer">
            <p className="has-text-white">© 2023 Team2. All rights reserved. Made with ♥️ by Iconicto</p>
        </section>
    );
}

export default SiteFooter;