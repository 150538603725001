import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bulma/css/bulma.min.css';
import 'aos/dist/aos.css';
import NavBar from './common/NavBar';
import SiteFooter from './screens/HomePage/components/Footer';
import { SnackbarProvider } from 'notistack'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SnackbarProvider autoHideDuration={5000} maxSnack={3} dense
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <NavBar />
      <App />
      <SiteFooter />
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
