import React, { useEffect, useState } from 'react';
import "./index.css";
import { useParams, useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { PortfolioItems } from '../HomePage/components/Portfolio/portfolio';
import ScaleLoader from 'react-spinners/ScaleLoader';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const ShowcasePage = () => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [activeJob, setActiveJob] = useState(null);
    let params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(params)
        const job = PortfolioItems.find(item => item.url === params.type);
        console.log(job)
        setActiveJob(job);
    }, [])

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const changePage = (location) => {
        navigate('/portfolio/' + location);
    }

    return (
        <section className="showcase-page">
            <div className="showcase-content">
                <h1 className="title is-2 showcase-title mb-6">Example Projects</h1>

                <div className="columns mt-6">
                    <div className="column is-2 has-text-left">
                        <aside className="menu">
                            <p className="menu-label">
                                Example Projects
                            </p>
                            <ul className="menu-list">
                                <li><a className={params.type === "alta" && "is-active"} href="/portfolio/alta">Alta</a></li>
                                <li><a className={params.type === "boundary" && "is-active"} href="/portfolio/boundary">Boundary</a></li>
                                <li><a className={params.type === "commercial" && "is-active"} href="/portfolio/commercial">Commercial</a></li>
                                <li><a className={params.type === "elevation-certificate" && "is-active"} href="/portfolio/elevation-certificate">Elevation Certificate</a></li>
                                <li><a>Metes & Bounds</a></li>
                                <li>
                                    <ul className="menu-list">
                                        <li><a className={params.type === "metes-and-bounds-big" && "is-active"} href="/portfolio/metes-and-bounds-big">Big Job</a></li>
                                        <li><a className={params.type === "metes-and-bounds-small" && "is-active"} href="/portfolio/metes-and-bounds-small">Small Job</a></li>
                                    </ul>
                                </li>
                                <li><a className={params.type === "re-plat" && "is-active"} href="/portfolio/re-plat">Re-Plat</a></li>
                                <li><a className={params.type === "sidewalk" && "is-active"} href="/portfolio/sidewalk">Sidewalk Project</a></li>
                                <li><a className={params.type === "spot-elevations" && "is-active"} href="/portfolio/spot-elevations">Spot Elevations</a></li>
                                <li><a className={params.type === "topo-survey" && "is-active"} href="/portfolio/topo-survey">Topo Survey (Big)</a></li>
                                <li><a className={params.type === "topography-survey" && "is-active"} href="/portfolio/topography-survey">Topography Survey</a></li>
                                <li><a className={params.type === "tree-survey" && "is-active"} href="/portfolio/tree-survey">Tree Survey</a></li>
                                <li><a className={params.type === "typical-small-job" && "is-active"} href="/portfolio/typical-small-job">Typical Small Job</a></li>
                            </ul>
                        </aside>
                    </div>
                    <div className="column is-5 has-text-centered">
                        <h1 className="title is-4">From USA</h1>
                        <div className="has-text-left pl-6 pr-6 mb-5">
                            <div class="notification is-link is-light">
                                <p>What we need from you:</p>
                                <ol className="ml-6">
                                    <li>Text File</li>
                                    <li>Sketch</li>
                                    <li>Site Images</li>
                                    <li>Title Commitment</li>
                                </ol>
                            </div>
                        </div>
                        <div className="has-text-left">
                            <h1 className="title is-6 pl-6 pr-6 mb-5">Sample Sketch</h1>
                        </div>
                        {activeJob ? <a href={activeJob?.toUSA} target="_blank">
                            <div className="card ml-6 mr-6 m-23">
                                <Document file={activeJob.toUSA} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page width={300} rotate={activeJob.toRot} pageNumber={pageNumber} />
                                </Document>
                            </div>
                        </a> : <ScaleLoader color="#082c66" />}
                        <a href={activeJob?.ref} download>
                            <button className="button is-primary-button mt-5 text-download-btn" disabled={!activeJob?.ref}>Download Text Reference</button>
                        </a>
                    </div>
                    <div className="column is-5 has-text-centered">
                        <h1 className="title is-4">To USA</h1>
                        {activeJob ? <a href={activeJob.fromUSA} target="_blank">
                            <Document file={activeJob?.fromUSA} onLoadSuccess={onDocumentLoadSuccess} externalLinkTarget={"_blank"} onItemClick={(e) => console.log(e)}>
                                <Page width={300} rotate={activeJob.fromRot} scale={1.0} pageNumber={pageNumber} />
                            </Document>
                        </a> : <ScaleLoader color="#082c66" />}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ShowcasePage;


