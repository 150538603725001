import React from 'react';
import AboutUsImage from "../../../../assets/img/about-img.jpeg";
import './index.css';
import sericesList from './services';

const AboutUs = () => {
    return (
        <section className="about-us landing-section">
            <div className="columns">
                <div className="column is-6">
                    <h1 className="title is-1 about-us-text mb-2" data-aos="fade-up" data-aos-duration="900">
                        About Us.
                    </h1>
                    <h1 className="title is-4 mb-6" data-aos="fade-up" data-aos-duration="900">Who we are and what we do at Team 2</h1>
                    <p className="subtitle is-5 mt-6 has-text-justified" data-aos="fade-up" data-aos-duration="900">
                        Team 2 is an outsourcing company specializing in Land Surveying drafting services for Civil Engineers and Professional Land Surveyors.
                        We have over 15 years of experience in providing drafting services to U.S. Civil Engineering and Land Surveying companies, with over 150,000 projects completed.
                        We offer customers in the US  solutions to help their businesses grow, providing accurate technical documents that save costs at the fastest possible turnaround time.
                    </p>
                    <p className="subtitle is-5 mt-2 has-text-justified" data-aos="fade-up" data-aos-duration="900">
                        Team 2 staff are highly qualified with years of experience. Providing short notice staffing that allows customers to scale up their businesses without worrying about space and procurement requirements, Team 2 has the ability to complete a survey drawing in anywhere from 24 to 72 hours depending on the complexity of the drawing.
                        With Team 2 back-office support our customers have been able take on more projects at lower costs, increasing revenue and profit.
                    </p>
                    <p className="subtitle is-5 mt-2 has-text-justified" data-aos="fade-up" data-aos-duration="900">
                        <b>At Team 2 we take pride in the technical accuracy and production speed of our work.</b>
                    </p>
                </div>
                <div className="column is-6 has-text-centered is-align-self-center">
                    <img className="about-image" src={AboutUsImage} alt="" data-aos="fade-in" data-aos-duration="900" />
                </div>
            </div>
            <div className="about-us-services has-text-centered mt-6">
                <h1 className="title is-4 mb-6">Type of servicers we offer </h1>
                <div className="about-us-services-grid">
                    {
                        sericesList.map((item, index) => {
                            return (
                                <div class="card about-us-service-card" key={index + 1}>
                                    <div class="card-content">
                                        <div class="content">
                                            <i class="fas fa-pencil-ruler service-card-icon"></i>
                                            <h1 className="title is-5 about-us-service-card-title">{item}</h1>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default AboutUs;