import altaImg from "../../../../assets/projects/alta/img.svg"
import altaTo from "../../../../assets/projects/alta/to.pdf"
import altaFrom from "../../../../assets/projects/alta/from.pdf"
import altaRef from "../../../../assets/projects/alta/ref.txt"

import boundaryImg from "../../../../assets/projects/Boundary/img.svg"
import boundaryTo from "../../../../assets/projects/Boundary/to.pdf"
import boundaryFrom from "../../../../assets/projects/Boundary/from.pdf"
import boundaryRef from "../../../../assets/projects/Boundary/ref.txt"

import commercialImg from "../../../../assets/projects/Commercial/img.svg"
import commercialTo from "../../../../assets/projects/Commercial/to.pdf"
import commercialFrom from "../../../../assets/projects/Commercial/from.pdf"
import commercialRef from "../../../../assets/projects/Commercial/ref.txt"

import elevationImg from "../../../../assets/projects/Elevation Certificate/img.svg"
import elevationTo from "../../../../assets/projects/Elevation Certificate/to.pdf"
import elevationFrom from "../../../../assets/projects/Elevation Certificate/from.pdf"
import elevationRef from "../../../../assets/projects/Elevation Certificate/ref.txt"

import mnbBigImg from "../../../../assets/projects/Metes & Bounds Job (Big)/img.svg"
import mnbBigTo from "../../../../assets/projects/Metes & Bounds Job (Big)/to.pdf"
import mnbBigFrom from "../../../../assets/projects/Metes & Bounds Job (Big)/from.pdf"
import mnbBigRef from "../../../../assets/projects/Metes & Bounds Job (Big)/ref.txt"

import mnbSmallTo from "../../../../assets/projects/Metes & Bounds Job (Small)/to.pdf"
import mnbSmallFrom from "../../../../assets/projects/Metes & Bounds Job (Small)/from.pdf"
import mnbSmallRef from "../../../../assets/projects/Metes & Bounds Job (Small)/ref.txt"

import replatImg from "../../../../assets/projects/Re-Plat/img.svg"
import replatTo from "../../../../assets/projects/Re-Plat/to.pdf"
import replatFrom from "../../../../assets/projects/Re-Plat/from.pdf"
import replatRef from "../../../../assets/projects/Re-Plat/ref.txt"

import sidewalkImg from "../../../../assets/projects/Sidewalk Project/img.svg"
import sidewalkTo from "../../../../assets/projects/Sidewalk Project/to.pdf"
import sidewalkFrom from "../../../../assets/projects/Sidewalk Project/from.pdf"
import sidewalkRef from "../../../../assets/projects/Sidewalk Project/ref.txt"

import spotElevationImg from "../../../../assets/projects/Spot Elevations/img.svg"
import spotElevationTo from "../../../../assets/projects/Spot Elevations/to.pdf"
import spotElevationFrom from "../../../../assets/projects/Spot Elevations/from.pdf"
import spotElevationRef from "../../../../assets/projects/Spot Elevations/ref.txt"

import topoSurveyImg from "../../../../assets/projects/Topo Survey (Big)/img.svg"
import topoSurveyTo from "../../../../assets/projects/Topo Survey (Big)/to.pdf"
import topoSurveyFrom from "../../../../assets/projects/Topo Survey (Big)/from.pdf"
import topoSurveyRef from "../../../../assets/projects/Topo Survey (Big)/ref.txt"

import topographySurveyImg from "../../../../assets/projects/Topography Survey/img.svg"
import topographySurveyTo from "../../../../assets/projects/Topography Survey/to.pdf"
import topographySurveyFrom from "../../../../assets/projects/Topography Survey/from.pdf"
import topographySurveyRef from "../../../../assets/projects/Topography Survey/ref.txt"

import treeSurveyImg from "../../../../assets/projects/Tree Survey/img.svg"
import treeSurveyTo from "../../../../assets/projects/Tree Survey/to.pdf"
import treeSurveyFrom from "../../../../assets/projects/Tree Survey/from.pdf"
import treeSurveyRef from "../../../../assets/projects/Tree Survey/ref.txt"

import tsjImg from "../../../../assets/projects/Typical Small Job/img.svg"
import tsjTo from "../../../../assets/projects/Typical Small Job/to.pdf"
import tsjFrom from "../../../../assets/projects/Typical Small Job/from.pdf"
import tsjRef from "../../../../assets/projects/Typical Small Job/ref.txt"

export const PortfolioItems = [
    {
        name: "Alta",
        image: altaImg,
        fromUSA: altaTo,
        toUSA: altaFrom,
        url: "alta",
        ref: altaRef,
        toRot: 0,
        fromRot: 0

    },
    {
        name: "Boundary",
        image: boundaryImg,
        fromUSA: boundaryTo,
        toUSA: boundaryFrom,
        url: "boundary",
        ref: boundaryRef,
        toRot: 90,
        fromRot: 90
    },
    {
        name: "Commercial",
        image: commercialImg,
        fromUSA: commercialTo,
        toUSA: commercialFrom,
        url: "commercial",
        ref: commercialRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Elevation Certificate",
        image: elevationImg,
        fromUSA: elevationTo,
        toUSA: elevationFrom,
        url: "elevation-certificate",
        ref: elevationRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Metes & Bounds Job",
        image: mnbBigImg,
        fromUSA: mnbBigTo,
        toUSA: mnbBigFrom,
        url: "metes-and-bounds-big",
        ref: mnbBigRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Metes & Bounds Job",
        image: mnbBigImg, //Same image for both big and small
        fromUSA: mnbSmallTo,
        toUSA: mnbSmallFrom,
        url: "metes-and-bounds-small",
        ref: mnbSmallRef,
        toRot: 90,
        fromRot: 90
    },
    {
        name: "Re-Plat",
        image: replatImg,
        fromUSA: replatTo,
        toUSA: replatFrom,
        url: "re-plat",
        ref: replatRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Sidewalk Project",
        image: sidewalkImg,
        fromUSA: sidewalkTo,
        toUSA: sidewalkFrom,
        url: "sidewalk",
        ref: sidewalkRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Spot Elevations",
        image: spotElevationImg,
        fromUSA: spotElevationTo,
        toUSA: spotElevationFrom,
        url: "spot-elevations",
        ref: spotElevationRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Topo Survey (Big)",
        image: topoSurveyImg,
        fromUSA: topoSurveyTo,
        toUSA: topoSurveyFrom,
        url: "topo-survey",
        ref: topoSurveyRef,
        toRot: 0,
        fromRot: 90
    },
    {
        name: "Topography Survey",
        image: topographySurveyImg,
        fromUSA: topographySurveyTo,
        toUSA: topographySurveyFrom,
        url: "topography-survey",
        ref: topographySurveyRef,
        toRot: 90,
        fromRot: 90
    },
    {
        name: "Tree Survey",
        image: treeSurveyImg,
        fromUSA: treeSurveyTo,
        toUSA: treeSurveyFrom,
        url: "tree-survey",
        ref: treeSurveyRef,
        toRot: 90,
        fromRot: 0
    },
    {
        name: "Typical Small Job",
        image: tsjImg,
        fromUSA: tsjTo,
        toUSA: tsjFrom,
        url: "typical-small-job",
        ref: tsjRef,
        toRot: 0,
        fromRot: 0
    },
]