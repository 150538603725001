import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import './index.css';

const Contact = () => {

    const [formData, setFormData] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        //validations
        if (!formData.name) {
            enqueueSnackbar("Please enter your name", { variant: 'error' });
            setLoading(false);
            return;
        }
        if (!formData.email) {
            enqueueSnackbar("Please enter your email", { variant: 'error' });
            setLoading(false);
            return;
        }
        if (!formData.message) {
            enqueueSnackbar("Please enter your message", { variant: 'error' });
            setLoading(false);
            return;
        }
        if (!formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            enqueueSnackbar("Please enter a valid email", { variant: 'error' });
            setLoading(false);
            return;
        }

        //send email
        let data = {
            service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
            template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            user_id: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            template_params: {
                'from_name': formData.name,
                'from_email': formData.email,
                'message': formData.message
            }
        };

        const emailResponse = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);

        if (!emailResponse?.data === "OK") {
            enqueueSnackbar("Something went wrong. Please try again later", { variant: 'error' });
            setLoading(false);
            return;
        }

        enqueueSnackbar("Email sent successfully", { variant: 'success' });
        setLoading(false);
        clearForm();
        window.location.href = "/";

    }

    const clearForm = () => {
        setFormData({});

    }

    return (
        <section className="contact-us landing-section has-text-centered" id="contact-us">
            <h1 className="title is-1 contact-us-title mb-1" data-aos="fade-up" data-aos-duration="900">Contact Us</h1>
            <h1 className="title is-4" data-aos="fade-up" data-aos-duration="900">or want to get the latest updates from us?</h1>
            <div className="contact-form" data-aos="zoom-in" data-aos-duration="900">
                <div className="columns">
                    <div className="column is-3"></div>
                    <div className="column is-6 has-text-left">
                        <form>
                            <div className="field">
                                <label className="label">Your Name</label>
                                <div className="control">
                                    <input className="input" type="text" placeholder="E.g.: John Doe"
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Your Email</label>
                                <div className="control">
                                    <input className="input" type="email" placeholder="E.g.:john@example.com"
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Your Message</label>
                                <div className="control">
                                    <textarea className="textarea" placeholder="E.g.: Can I know more about Team2?"
                                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="field is-grouped">
                                <div className="control">
                                    <button className={`button is-primary-button has-bold-text ${loading && "is-loading"}`} onClick={handleFormSubmit}>Submit</button>
                                </div>
                                <div className="control">
                                    <button className="button is-light" onClick={clearForm}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="column is-3"></div>
                </div>
            </div>
        </section>
    );
}

export default Contact;