import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const PortfolioCard = (props) => {

    const navigate = useNavigate();

    const redirectToDetails = (location) => {
        navigate('/portfolio/' + location);
    }

    return (
        <div
            className="card m-3 portfolio-card"
            data-aos="zoom-in"
            data-aos-duration="900"
            onClick={() => redirectToDetails(props.item.url)}
        >
            <div className="card-image">
                <figure className="image is-4by3">
                    <img src={props.item.image} alt="Placeholder image" />
                </figure>
            </div>
            <div className="card-content">
                <div className="media">
                    <div className="media-content">
                        <p className="title is-4">{props.item.name}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PortfolioCard;