import React, { useState } from 'react';
import NavLogo from "../../assets/img/logo.png"
import './index.css';

const NavBar = () => {

    const [isActive, setisActive] = useState(false);

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                    <img src={NavLogo} width="112" height="28" />
                </a>

                <a onClick={() => {
                    setisActive(!isActive);
                }}
                    role="button"
                    className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
                    aria-label="menu"
                    aria-expanded="false"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className="navbar-start">
                    <a className="navbar-item" href="/">Home</a>
                    <a className="navbar-item" href="/portfolio/alta">Portfolio</a>

                    {/* <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">Services</a>
                        <div className="navbar-dropdown">
                            <a className="navbar-item">About</a>
                            <a className="navbar-item">Jobs</a>
                            <a className="navbar-item">Contact</a>
                        </div>
                    </div> */}
                    
                    <a className="navbar-item" href="/#contact-us">Contact</a>
                </div>

                <div className="navbar-end">
                    {/* <div className="navbar-item">
                        <div className="buttons">
                            <a className="button is-warning is-yellow-button has-bold-text">
                                Sign in
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </nav>
    );
}

export default NavBar;