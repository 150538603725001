import React, { useState } from 'react';
import './index.css';
import PortfolioCard from './components/PortfolioCard';
import { PortfolioItems } from './portfolio';

const Portfolio = () => {

    return (
        <section className="portfolio-section landing-section" id="home_portfolio_section">
            <div className="columns">
                <div className="column is-9">
                    <h1 className="title is-1 portfolio-title" data-aos="fade-up" data-aos-duration="900">Example Projects</h1>
                </div>
            </div>
            <div className="columns mt-5 mb-6">
                <div className="column is-3"></div>
                <div className="column is-6">
                    <div className="notification portfolio-notification" data-aos="fade-up" data-aos-duration="900">
                        <strong>To ensure seamless delivery of our services, you would be required to provide us with the necessary Text File, Sketch, Site Images and Title Commitment.</strong>
                    </div>
                </div>
                <div className="column is-3"></div>
            </div>
            <div className="porfolio-grid-holder">
                <div className="portfolio-grid">
                    {
                        PortfolioItems.map((item, index) => {
                            // Stop rendering two items for Metes and Bounds Job
                            if (item.url !== "metes-and-bounds-small")
                                return (
                                    <PortfolioCard key={index} item={item} />
                                )
                        })
                    }
                </div>
            </div>



        </section>
    );
}

export default Portfolio;