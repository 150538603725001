const sericesList = [
    "Residential Title Surveys",
    "Commercial Title Surveys",
    "Metes and Bounds",
    "Platting/Re-Platting Surveys",
    "Topographic Surveys",
    "Boundary Surveys",
    "Elevation Certificates",
    "Tree Surveys",
    "ALTA",
    "Sidewalks Project",
    "Spot Elevations"
];

export default sericesList;